<template>
  <div class="demo-filterForm">
    <el-form :model="filterForm" ref="businessRequestFilter" :rules="businessRequestFilterFormRules">
      <el-form-item class="me-6" prop="time_period">
        <label class="d-block" for="">{{ $t("Time Period") }}</label>
        <el-select v-model="filterForm.time_period" :placeholder="$t('Time Period')">
          <el-option
            v-for="(item,index) in timePeriods"
            :key="index"
            :label="item.title"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="me-6 mb-10" prop="product_areas">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogProductAreaVisible = true" class="select-popup"></div>

          <label class="d-block" for="">{{ $t("Product Area") }}</label>
          <el-select
            v-model="filterForm.product_areas"
            multiple
            collapse-tags
            disabled
            :placeholder="$t('Select Product Area')">

            <el-option
              v-for="(item, index) in productAreas"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item class="me-6 mb-10" prop="bank_ids">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogBanksVisible = true" class="select-popup"></div>

          <label class="d-block">{{ $t("Banks") }}</label>
          <el-select
            v-model="filterForm.bank_ids"
            multiple
            disabled
            collapse-tags
            :placeholder="$t('Select Banks')"
          >
            <el-option
              v-for="(item,index) in banks"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
              {{ item.name }}
            </el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item class="me-6" prop="countries">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogCountriesVisible = true" class="select-popup"></div>

          <label class="d-block mt-5" for="">{{ $t("Country of the Bank") }}</label>
          <el-select
            v-model="filterForm.countries"
            multiple
            disabled
            collapse-tags
            :placeholder="$t('Select Countries')">
            <el-option
              v-for="(item, index) in countries"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item class="me-6" prop="priority">
        <label for="" class="d-block mt-5">{{ $t("Priority") }}</label>
        <el-checkbox-group class="business-priority" v-model="filterForm.priority">
          <el-checkbox
            v-for="(item, index) in priorities"
            :key="index"
            :label="item.value"
            :name="item.label"
          >
            {{ $t(item.label) }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <!--    <el-form-item>-->
      <!--      <div class="d-flex justify-content-between">-->
      <!--        <label for="" class="d-block">{{ $t("Sort by who created the request") }}</label>-->
      <!--        <p class="text-primary pointer" @click="dialogCreatorsVisible = true">{{ $t("View All") }}</p>-->
      <!--        <div v-if="dialogCreatorsVisible">-->
      <!--          <SelectModal-->
      <!--            :popupVisible="dialogCreatorsVisible"-->
      <!--            :dataForSelect="creators"-->
      <!--            multiple="true"-->
      <!--            @handle-close="dialogCreatorsVisible = false"-->
      <!--            @handle-confirm="handleCreatorsSelect"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <el-checkbox-group v-model="filterForm.creator_ids" class="d-flex">-->
      <!--        <div-->
      <!--          v-for="(creator, i) in creators"-->
      <!--          :key="i"-->
      <!--        >-->
      <!--          <el-checkbox-button-->
      <!--            v-if="i < 5"-->
      <!--            :label="creator.name"-->
      <!--            name="creators"-->
      <!--            class="custom-check">-->
      <!--            <div class="circle-image"></div>-->
      <!--            <p class="mt-3 mb-2 fw-bold">{{ creator.name }}</p>-->
      <!--            <p class="text-gray">{{ creator.position }}</p>-->
      <!--          </el-checkbox-button>-->
      <!--        </div>-->
      <!--      </el-checkbox-group>-->
      <!--    </el-form-item>-->

      <el-form-item class="switch me-6" label="Show closed requests" prop="delivery">
        <el-switch v-model="filterForm.display_closed_items"></el-switch>
      </el-form-item>

    </el-form>

    <div class="filters-counts mt-0 mb-6 me-6" v-show="filterLength">
      <span>
        <div class="filter-badges">{{ filterLength }}</div>
        {{ $t('Filters Selected') }}
      </span>
      <button @click="clearAll">{{ $t('Clear All') }}</button>
    </div>

    <button class="main-btn-outline filter-btn" @click="applyFilters">{{ $t("Apply Filters") }}</button>
  </div>

  <SelectModal
    v-if="dialogBanksVisible"
    :popupVisible="dialogBanksVisible"
    :dataForSelect="banks"
    :dataForUpdate="filterForm.bank_ids"
    :multiple="true"
    :title="$t('Select Banks')"
    :subtitle="$t('Select relevant banks')"
    :placeholder="$t('Search Banks')"
    @handle-close="dialogBanksVisible = false"
    @handle-confirm="handleBanksSelect"
  />

  <SelectModal
    v-if="dialogProductAreaVisible"
    :title="$t('Select Product Areas')"
    :subtitle="$t('Select Product Areas you want filter on')"
    :placeholder="$t('Search Product Areas')"
    :popupVisible="dialogProductAreaVisible"
    :dataForSelect="productAreas"
    :dataForUpdate="filterForm.product_areas"
    :multiple="true"
    :selfValue="true"
    @handle-close="dialogProductAreaVisible = false"
    @handle-confirm="handleConfirmProductAreas"
  />

  <SelectModal
    v-if="dialogCountriesVisible"
    :title="$t('Select Country')"
    :subtitle="$t('Select Country you want to filter on')"
    :placeholder="$t('Search Country')"
    :popupVisible="dialogCountriesVisible"
    :dataForSelect="countries"
    :dataForUpdate="filterForm.countries"
    :multiple="true"
    :selfValue="true"
    @handle-close="dialogCountriesVisible = false"
    @handle-confirm="handleConfirmCountries"
  />
</template>
<script>
  import SelectModal from "@/buying-teams/shared-components/modals/SelectModal";

  export default {
    name: "RequestFilter",

    components: {
      SelectModal
    },

    props: {
      banks: Array,
      countries: Array,
      productAreas: Array,
      timePeriods: Array,
      priorities: Array,
      initialFilters: Object,
      filterLength: Number
    },

    emits: [
      "apply-filters"
    ],

    created() {
      this.fillFilter(this.initialFilters);
    },

    data() {
      return {
        dialogBanksVisible: false,
        dialogCreatorsVisible: false,
        dialogProductAreaVisible: false,
        dialogCountriesVisible: false,
        filterForm: {
          product_areas: "",
          priority: [],
          creator_ids: [],
          bank_ids: [],
          display_closed_items: false,
        },
        businessRequestFilterFormRules: {
          time_period: [
            { required: true, message: this.$t("Please select Time Period"), trigger: "change" },
          ],
          bank_ids: [
            { required: true, message: this.$t("Please select Bank"), trigger: "change" }
          ],
          product_areas: [
            { required: true, message: this.$t("Please select Product Areas"), trigger: "change" }
          ],
          countries: [
            { required: true, message: this.$t("Please select Countries"), trigger: "change" }
          ],
          priority: [
            { required: true, message: this.$t("Please Select Priority"), trigger: "change" }
          ]
        }
      };
    },

    methods: {
      handleBanksSelect(event) {
        this.dialogFormVisible = false;
        this.filterForm.bank_ids = event;
      },
      handleConfirmProductAreas(event) {
        this.filterForm.product_areas = event;
        this.dialogProductAreaVisible = false;
      },
      handleConfirmCountries(event) {
        this.filterForm.countries = event;
        this.dialogCountriesVisible = false;
      },
      handleCreatorsSelect(event) {
        this.dialogFormVisible = false;
      },
      applyFilters() {
        this.$refs.businessRequestFilter.validate(valid => {
          if (valid) {
            this.$emit("apply-filters", this.filterForm);
          }
        })
      },
      closeFilter() {
        this.$emit("handle-close-filter");
      },
      fillFilter(filterData) {
        this.filterForm.bank_ids = filterData.bank_ids;
        this.filterForm.countries = filterData.countries;
        this.filterForm.product_areas = filterData.product_areas;
        this.filterForm.time_period = filterData.time_period;
        this.filterForm.display_closed_items = filterData.display_closed_items;
        this.filterForm.priority = filterData.priority;
      },
      clearAll() {
        this.filterForm.bank_ids = this.banks.map(b => b.id);
        this.filterForm.countries = this.countries;
        this.filterForm.product_areas = this.productAreas;
        this.filterForm.time_period = '*'
        this.filterForm.display_closed_items = false;
        this.filterForm.priority = this.priorities.map(p => p.value);
        this.applyFilters();
      }
    }
  };
</script>
<style lang="scss">
.el-checkbox-group.business-priority {
  display: flex;
  gap: 4px;
  .el-checkbox {
    height: 40px;
    padding: 12px;
    margin-right: 0;
    background: #F8F8F8;
    border: 2px solid #435BF4;
    border-radius: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: .3s ease-in-out;
    .el-checkbox__input {
      display: none;
    }
    .el-checkbox__label {
      padding-left: 0;
      color: #435BF4;
      transition: .3s ease-in-out;
    }

    &.is-checked {
      background: #435BF4;
      .el-checkbox__label {
        color: #fff;
      }
    }
  }
}
</style>
