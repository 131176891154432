<template>
    <div class="request-card" :class="(showFilter ? ' show-filter ' : '') + className">
        <div class="request-card__header">
            <div class="request-card__header--left">
                <div class="request-card__header--icon">
                    <img src="/media/buying/icons/request-card-icon.svg" alt="">
                </div>
                <div class="request-card__header--info info-business">
                    <img :src="requestData.creatorBusiness.icon_path" width="20" height="20" alt="">
                    <span>{{ requestData.creatorBusiness.name }}</span>
                </div>
                <div class="request-card__header--info info-user">
                    <img :src="requestData.creator.icon_path" width="14" height="14" alt="">
                    <span>{{ requestData.creator.full_name }}</span>
                </div>
                <div class="request-card__header--info">
                    <img src="/media/buying/icons/business.svg" width="14" height="12" alt="">
                    <span>{{ requestData.creator.job_title }}</span>
                </div>
            </div>

            <router-link
                v-if="requestData.permissions.can_edit"
                :to="`/business/feedback/request/${requestData.id}/edit`"
                class="request-card__header--edit"
            >
                <img src="/media/buying/icons/edit.svg" width="18" alt="">
                <span>{{ $t("Edit Request") }}</span>
            </router-link>
        </div>

        <div class="request-card__body">
            <div class="request-card__body--top">
                <div class="request-card__body--stats">
                    <StatusComponent
                        class="me-4"
                        :text="requestData.priority.priorityName"
                        :type="requestData.priority.priorityClass"
                    />

                    <StatusComponent
                        :text="requestData.status.statusName"
                        :type="requestData.status.statusClass"
                    />

                    <div class="request-card-date">
                        {{ $t("Created on") }}
                        <div v-html="dateFormatInTimeZone(requestData.created_at)" class="date-time-zone"></div>
                    </div>
                </div>

                <div class="request-card__body--comment">
                    <img src="/media/buying/icons/comment.svg" width="14" alt="">
                    <span>{{ requestData.comments_count }}</span>
                </div>
            </div>

            <router-link :to="`/business/feedback/request/${requestData.id}`" class="request-card__body--title">
                {{ requestData.title }}
            </router-link>

            <div class="request-card__body--text">
                {{ requestData.description }}
            </div>

            <div class="request-bank">
                <div class="request-bank__title">
                    {{ $t("Request for Bank") }}
                </div>

                <div class="request-bank__card" :class="requestData.is_internal ? 'internal' : 'shared'">
                    <div class="bank-info">
                        <div class="bank-info__name">
                            <img :src="requestData.bank.icon_path" alt="">
                            <span>{{ requestData.bank.name }}</span>
                        </div>
                        <div class="bank-info__countries">
                            <div class="bank-info__countries--label">
                                {{ requestData.bank.countries.length === 1 ? $t("Country") : $t("Countries") }}
                            </div>
                            <div class="bank-info__countries--item"
                                 v-for="(item, index) in requestData.bank.countries.slice(0, 4)" :key="index">
                                {{ item }}
                            </div>
                            <div class="bank-info__countries--item" v-if="requestData.bank.countries.length > 4">
                                +{{ requestData.bank.countries.length - 4 }}
                            </div>
                        </div>
                    </div>
                    <div class="bank-label">
                        <img v-if="requestData.is_internal" src="/media/buying/icons/request-internal-icon.svg"
                             width="12" alt="">
                        <img v-else src="/media/buying/icons/request-shared-icon.svg" width="12" alt="">
                        <span>{{ requestData.is_internal ? $t("Internal") : $t("Shared") }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="request-card__footer">
            <div class="request-card__footer--left">
                <div class="request-card__footer--id">
                    {{ $t("ID") }} <strong>{{ requestData.id }}</strong>
                </div>
                <div class="request-card__footer--product">
                    <span>{{ $t("Product Areas") }}</span>
                    <div class="product-area-item" v-for="item in requestData.product_areas.slice(0, 3)">
                        {{ item }}
                    </div>
                    <div class="product-area-item" v-if="requestData.product_areas.length > 3">
                        +{{ requestData.product_areas.length - 3 }}
                    </div>
                </div>
            </div>

            <button
                v-if="currentUser.can(BusinessUserPermissionTypeEnum.OPEN_CLOSE_REQUESTS_AND_IDEAS)"
                class="open-close-btn mb-0"
                :class="{
                    'close': requestData.status.code === RequestStatusEnum.OPEN,
                    'open': requestData.status.code === RequestStatusEnum.CLOSED,
                    'buttonLoading': buttonLoading
                }"
                @click="toggleRequestStatus(requestData.id)"
                :disabled="buttonLoading"
            >
                <template v-if="buttonLoading">
                    <span class="spinner-border spinner-border-sm align-middle me-4"></span>
                    {{ requestData.status.code === RequestStatusEnum.OPEN ? $t("Closing") : $t("Opening") }}
                </template>
                <template v-else>
                    <img src="/media/buying/icons/close-red.svg"
                         v-if="requestData.status.code === RequestStatusEnum.OPEN" class="me-3" alt="">
                    {{ requestData.status.code === RequestStatusEnum.OPEN ? $t("Close Request") : $t("Open Request") }}
                </template>
            </button>
        </div>
    </div>
</template>

<script>
import StatusComponent from "@/buying-teams/shared-components/utils/StatusComponent";
import { RequestStatusEnum } from "@/store/enums/request/RequestStatusEnum";
import { dateFormatInTimeZone } from "@/core/helpers/GlobalHelper";
import store from "@/store";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export default {
    name: "BusinessRequestItem",
    props: {
        requestData: Object,
        showFilter: Boolean,
        className: String
    },
    emits: ["handle-toggle-status"],
    data() {
        return {
            RequestStatusEnum,
            dateFormatInTimeZone,
            BusinessUserPermissionTypeEnum
        };
    },
    components: {
        StatusComponent
    },
    computed: {
        buttonLoading() {
            return this.$store.getters.getToggleRequestStatusLoading === this.requestData.id;
        },
        currentUser() {
            return store.getters.currentUser;
        }
    },
    methods: {
        toggleRequestStatus(requestId) {
            this.$emit("handle-toggle-status", requestId);
        }
    }
};
</script>

<style lang="scss">
.request-card {
    box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    background: #fff;

    &__header {
        padding: 14px 24px;
        background: #F7F7F7;
        border-radius: 12px 12px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--left {
            display: flex;
            align-items: center;
            overflow-x: auto;
            padding-bottom: 7px;
            margin-bottom: -7px;

            > * {
                white-space: pre;
            }
        }

        &--edit {
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: .3s;
            white-space: nowrap;
            margin-left: 10px;

            &:hover {
                opacity: 0.8;
            }

            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #435BF4;
                margin-left: 12px;
            }
        }

        &--icon {
            padding: 2px 8px 2px 0;
            border-right: 1px solid rgba(0, 0, 0, 0.05);
        }

        &--info {
            display: flex;
            align-items: center;
            padding-left: 8px;

            &:not(:last-child) {
                padding-right: 8px;
                margin-right: 8px;
                border-right: 1px solid rgba(0, 0, 0, 0.05);
            }

            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
                margin-left: 6px;
            }

            &.info-business {
                span {
                    margin-left: 12px;
                }

                img {
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            &.info-user {
                img {
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }

    &__body {
        padding: 20px 19px 0 24px;

        &--top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;
        }

        &--stats {
            display: flex;
            align-items: center;
            overflow-x: auto;
            padding-bottom: 4px;
            margin-bottom: -4px;

            > * {
                white-space: pre;
            }

            .request-card-date {
                margin-left: 13px;
                font-weight: 400;
                font-size: 11px;
                line-height: 158.02%;
                color: #969696;

                span {
                    color: #000000;
                    font-weight: 400;
                }
            }
        }

        &--comment {
            display: flex;
            align-items: center;
            margin-left: 10px;

            span {
                font-weight: 600;
                font-size: 14px;
                line-height: 178.52%;
                color: #9CA0AB;
                margin-left: 2px;
            }
        }

        &--title {
            font-weight: 600;
            font-size: 16px;
            line-height: 158.02%;
            color: #2B2B2B;
            margin-bottom: 8px;
            display: inline-block;
        }

        &--text {
            font-weight: 400;
            font-size: 14px;
            line-height: 158.02%;
            color: #595959;
            margin-bottom: 14px;
        }

        .request-bank {
            padding-bottom: 12px;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                height: 1px;
                left: 5px;
                right: 5px;
                background: rgba(0, 0, 0, 0.05);
            }

            &__title {
                font-weight: 400;
                font-size: 12px;
                line-height: 158.02%;
                color: #969696;
                margin-bottom: 10px;
            }

            &__card {
                display: flex;
                gap: 8px;

                .bank-info {
                    display: flex;
                    align-items: center;
                    border-radius: 8px;
                    width: 100%;
                    padding: 7.5px 10px;
                    overflow-x: auto;

                    > * {
                        white-space: pre;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: rgba(2, 2, 2, 0.05);
                        border-radius: 6px;
                    }

                    &__name {
                        padding-right: 20px;
                        border-right: 1px solid rgba(0, 0, 0, 0.05);

                        span {
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 158.02%;
                            color: #2B2B2B;
                        }

                        img {
                            width: 26px;
                            height: 26px;
                            border-radius: 50%;
                            object-fit: cover;
                            object-position: center;
                            margin-right: 8px;
                            border: 1px solid #00000014;
                        }
                    }

                    &__countries {
                        padding-left: 10px;
                        display: flex;
                        align-items: center;

                        &--label {
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 158.02%;
                            color: #969696;
                            margin-right: 10px;
                        }

                        &--item {
                            padding: 8px 16px;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 15px;
                            color: #000000;
                            border-radius: 5px;

                            &:not(:last-child) {
                                margin-right: 6px;
                            }
                        }
                    }
                }

                .bank-label {
                    border-radius: 8px;
                    padding: 8px;
                    display: flex;
                    align-items: center;

                    span {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 158.02%;
                        color: #000000;
                        margin-left: 8px;
                    }
                }

                &.internal {
                    .bank-info {
                        background: #FAFAFA;
                        border: 1px solid #FAFAFA;

                        &__countries {
                            &--item {
                                background: #FFFFFF;
                                border: 1px solid rgba(0, 0, 0, 0.05);
                            }
                        }
                    }

                    .bank-label {
                        background: #FAFAFA;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                    }
                }

                &.shared {
                    .bank-info {
                        background: #FFF7EE;
                        border: 1px solid #FFE3C1;

                        &__countries {
                            &--item {
                                background: #FFF3E6;
                                border: 1px solid #FFE3C1;
                            }
                        }
                    }

                    .bank-label {
                        background: #FFF7EE;
                        border: 1px solid #FF930F;
                    }
                }
            }
        }
    }

    &__footer {
        padding: 20px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--left {
            display: flex;
            align-items: center;
            overflow-x: auto;
            max-width: 100%;
            padding-bottom: 5px;
            margin-bottom: -5px;

            > * {
                white-space: pre;
            }
        }

        &--id {
            font-weight: 300;
            font-size: 12px;
            line-height: 13px;
            color: #696969;
            padding-right: 22px;
            margin-right: 22px;
            border-right: 1px solid rgba(0, 0, 0, 0.05);

            strong {
                color: #000000;
            }
        }

        &--product {
            display: flex;
            align-items: center;

            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 158.02%;
                color: #969696;
                margin-right: 6px;
            }

            .product-area-item {
                background: #F8F8F8;
                border-radius: 5px;
                padding: 8px 7px;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #000000;

                &:not(:last-child) {
                    margin-right: 6px;
                }
            }
        }
    }

    &.show-filter {
        .request-card__body .request-bank__card {
            flex-direction: column;
            align-items: flex-start;
        }

        .request-card__footer {
            flex-direction: column;
            align-items: flex-start;
            gap: 14px;
        }
    }

    .open-close-btn {
        white-space: nowrap;
        margin-left: 5px;
    }
}

@media (max-width: 768px) {
    .request-card__header {
        padding: 8px 14px;

        &--icon {
            img {
                width: 18px;
            }
        }

        &--info {
            span {
                font-size: 12px;
            }
        }
    }
    .request-card__body {
        padding: 8px 12px 0 14px;

        .request-bank__card {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .request-card {
        &__footer {
            flex-direction: column;
            align-items: flex-start;
            gap: 14px;
            padding: 8px 14px;
        }

        .open-close-btn {
            margin-left: inherit;
        }

        &__body {
            &--stats {
                .status-label {
                    font-size: 11px;
                }
            }

            .request-bank__card {
                .bank-info {
                    flex-direction: column;
                    align-items: flex-start;
                    overflow-x: revert;

                    &__name {
                        padding-right: 0;
                        border-right: 0;
                        margin-bottom: 10px;
                    }

                    &__countries {
                        overflow-x: auto;
                        max-width: 100%;
                        padding-bottom: 5px;
                        margin-bottom: -5px;

                        > * {
                            white-space: pre;
                        }
                    }
                }
            }
        }
    }
    .request-card__header--edit {
        span {
            display: none;
        }
    }
}
</style>
