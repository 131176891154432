<template>
    <div
        class="feedbacks-page card card-padding h-100 business-request-page"
        :class="{ 'is-show-filter': showFilter }"
        v-if="!filterLoading"
    >
        <PageHeader
            @showFilter="showFilter = true"
            :showFilter="showFilter"
            :search-input-text="$t('Search requests...')"
            @handleInput="handleSearch"
        >
            <template #filter-badges>
                <BusinessFilterBadges
                    :initialFilters="filterData.filter"
                    :banks="businessBanks"
                    :countries="businessCountries"
                    :productAreas="businessProductAreas"
                    :timePeriods="timePeriods"
                    :priorities="priorities"
                    @setFilterLength="filterLength = $event"
                />
            </template>
        </PageHeader>

        <div class="feedbacks-wrapper">
            <div
                v-if="showFilter"
                class="filter feedbacks-wrapper__filter">
                <p
                    @click="showFilter = false"
                    class="closeFilter">
                    <img width="20" src="/media/buying/icons/close.svg" alt="">
                </p>
                <BusinessRequestFilter
                    :initialFilters="filterData.filter"
                    :banks="businessBanks"
                    :countries="businessCountries"
                    :productAreas="businessProductAreas"
                    :timePeriods="timePeriods"
                    :filterLength="filterLength"
                    :priorities="priorities"
                    @apply-filters="applyRequestFilters"
                />
            </div>
            <div class="feedbacks-wrapper__list">
                <template
                    v-if="!loading && requests.length"
                    v-for="request in requests"
                    :key="request.id"
                >
                    <BusinessRequestItem
                        :requestData="request"
                        :showFilter="showFilter"
                        class-name="mt-8"
                        @handle-toggle-status="toggleRequestStatus(request)"
                    />
                </template>
                <div
                    v-else-if="!loading && !requests.length"
                    class="h-100 d-flex align-items-center justify-content-center"
                >
                    <RequestIdeaEmptyState
                        :title="$t('No Request Found')"
                        :text="filterLength
                            ? $t('There are no open requests to be found. This might have happened due to the filters applied.')
                            : $t('There are no open requests yet. Please check back later.')
                        "
                        :icon-path="filterLength
                            ? '/media/buying/icons/no-request-found-filter.png'
                            : '/media/buying/icons/no-request-found.svg'
                        "
                        :show-button="true"
                        :button-text="$t('Create Request')"
                        @handleClickButton="$router.push('/business/feedback/request/create')"
                    />
                </div>
                <CustomLoader height="400px" v-else/>
            </div>
        </div>
        <div></div>
    </div>
</template>
<script>
import BusinessRequestItem from "@/buying-teams/pages/business/requests/BusinessRequestItem";
import BusinessRequestFilter from "@/buying-teams/pages/business/requests/BusinessRequestFilter";
import store from "@/store";
import CustomLoader from "../../../shared-components/utils/CustomLoader";
import {BusinessRequestFilterModel} from "@/store/models/filter/BusinessRequestFilterModel";
import {RequestStatusEnum} from "@/store/enums/request/RequestStatusEnum";
import {RequestStatusModel} from "@/store/models/request/RequestStatusModel";
import BusinessFilterBadges from "@/buying-teams/shared-components/filter/BusinessFilterBadges";
import {setCurrentPageBreadcrumbs, setCurrentPageCounter} from "@/core/helpers/breadcrumb";
import PageHeader from "@/buying-teams/shared-components/feedbacks/PageHeader";
import RequestIdeaEmptyState from "@/buying-teams/shared-components/utils/RequestIdeaEmptyState";
import BusinessFiltersTypeEnum from "@/store/enums/business/BusinessFiltersTypeEnum";

export default {
    name: "BusinessRequests",

    components: {
        RequestIdeaEmptyState,
        PageHeader,
        BusinessFilterBadges,
        BusinessRequestFilter,
        BusinessRequestItem,
        CustomLoader
    },

    data() {
        return {
            filterLoading: true,
            loading: true,
            requests: [],
            showFilter: false,
            searchTimeoutId: null,
            filterLength: 0,
            filterData: {
                page: 0,
                type: "request",
                search: "",
                filter: {
                    bank_ids: [],
                    time_period: "*",
                    product_areas: ["*"],
                    countries: [],
                    display_closed_items: false,
                    priority: []
                }
            }
        };
    },

    computed: {
        businessBanks() {
            return store.getters.businessBanks;
        },
        businessCountries() {
            return store.getters.businessCountries;
        },
        businessProductAreas() {
            return store.getters.businessProductAreas;
        },
        businessRequestFilter() {
            return store.getters.getBusinessFiltersState.requests;
        },
        timePeriods() {
            return store.getters.timePeriods;
        },
        priorities() {
            return store.getters.priority;
        },
    },
    created() {
        setCurrentPageBreadcrumbs({
            title: this.$t('Requests'),
            subTitle: this.$t('Requests for your Banks (action, change, other)')
        })
    },
    async mounted() {
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.REQUESTS);
        this.filterLoading = false;
        this.setFilters(this.businessRequestFilter);
        await this.getRequests(this.filterData);
    },

    methods: {
        async getRequests(filter) {
            this.requests.length = 0;
            this.loading = true;
            this.requests = await store.dispatch("getRequests", filter)
                .finally(() => {
                    this.loading = false;
                });

            setCurrentPageCounter({counter: this.requests.length})
        },
        async applyRequestFilters(filters) {
            this.setFilters(new BusinessRequestFilterModel(filters));
            await this.getRequests(this.filterData);
        },
        setFilters(filters) {
            this.filterData.filter = filters;
        },
        async toggleRequestStatus(request) {
            let status   = request.status.code === RequestStatusEnum.OPEN ? RequestStatusEnum.CLOSED : RequestStatusEnum.OPEN
            const params = {
                id: request.id,
                status: status
            }
            await store.dispatch('toggleRequestStatus', params)
                .then(res => {
                    if (res) {
                        request.status = new RequestStatusModel(status);
                    }
                })
        },
        async handleSearch(event) {
            let search = event.target.value

            if (search !== this.filterData.search && this.searchTimeoutId) clearTimeout(this.searchTimeoutId)
            this.filterData.search = search;

            this.searchTimeoutId = setTimeout(async () => {
                await this.getRequests(this.filterData);
            }, 500);
        }
    }
};
</script>
<style lang="scss">
.business-request-page {
    @media (max-width: 768px) {
        .page-header {
            flex-wrap: nowrap !important;
            padding-top: 20px;

            > .w-100 {
                width: max-content !important;
                margin-top: 0 !important;
            }
        }
    }
}
</style>
